<template>
  <section class="container px-0 pb-lg">
    <!-- desktop bg -->
    <div class="become-content-provider-bg d-none d-sm-block"></div>
    <!-- mobile bg -->
    <div class="become-content-provider-bg-mobile d-sm-none"></div>

    <div class="newsletter-section-content row pt-sm-lg px-3 px-sm-sm">
      <div
        class="col-12 col-sm-10 col-md-8 col-lg-6 d-flex flex-column align-items-center mx-auto mx-md-0"
      >
        <logo
          variation="black_full"
          class="mb-sm"
          :style="{ maxWidth: '100%' }"
        ></logo>

        <form v-if="!registered" class="row w-100" @submit.prevent="register">
          <h2
            class="w-100 text-md text-sm-lg text-center font-weight-600 mb-md"
          >
            Become a content provider <br />
            Let's start working together
          </h2>

          <div class="col-12 col-sm-6 mb-2">
            <base-input
              v-model="name"
              input-classes="border-primary font-weight-600"
              placeholder="First Name"
              :error="errorFor('name')"
            ></base-input>
          </div>

          <div class="col-12 col-sm-6 mb-2">
            <base-input
              v-model="lastname"
              input-classes="border-primary font-weight-600"
              placeholder="Last Name"
              :error="errorFor('last_name')"
            ></base-input>
          </div>

          <div class="col-12 mb-2">
            <base-input
              v-model="email"
              type="email"
              input-classes="border-primary font-weight-600"
              placeholder="Email Address"
              :error="errorFor('email')"
            ></base-input>
          </div>

          <div class="col-12 mb-4">
            <textarea
              v-model="message"
              class="form-control border-primary font-weight-600"
              rows="3"
              placeholder="Message"
              :error="errorFor('message')"
            ></textarea>

            <div
              class="text-danger invalid-feedback d-block"
              v-if="errorFor('message')"
            >
              {{ errorFor("message") }}
            </div>
          </div>

          <div class="col-12 mb-sm">
            <base-button
              type="primary"
              native-type="submit"
              class="w-100"
              :disabled="processing"
            >
              Become a Content Provider 🥋
            </base-button>
          </div>

          <div v-if="apiError" class="w-100">
            <p class="text-sm text-center text-danger font-weight-600">
              {{ apiError }}
            </p>
          </div>
        </form>

        <div v-else class="text-center">
          <img
            src="~@/assets/img/youkoso.svg"
            :style="{ maxWidth: '350px' }"
            alt="Thanks for subscribing"
            class="img-fluid"
          />

          <h2 class="text-md text-sm-lg text-center font-weight-600 mb-sm">
            Welcome to Bujin.tv!<br />
            The first video streaming platform <br />
            for martial arts is waiting for you!
          </h2>

          <p
            class="text-lg text-sm-xl text-center lh-130 font-weight-400 mb-md"
          >
            We have received your information successfully and will come back to
            you soon!
          </p>

          <div class="d-flex align-items-center justify-content-center">
            <span class="mr-1">Follow us</span>
            <a
              href="https://www.instagram.com/bujintv"
              class="mr-1"
              target="_blank"
            >
              <img
                src="~@/assets/img/instagram_icon_black.svg"
                alt="Bujin.tv Instagram"
                :style="{ width: '40px' }"
              />
            </a>
            <a href="https://www.facebook.com/bujintv" target="_blank">
              <img
                src="~@/assets/img/facebook_icon_black.svg"
                alt="Bujin.tv Facebook"
                :style="{ width: '40px' }"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import handlesApiErrors from "@/mixins/handlesApiErrors";
import Logo from "@/components/Logo";

export default {
  name: "newsletter",

  mixins: [handlesApiErrors],

  components: { Logo },

  data() {
    return {
      name: "",
      lastname: "",
      email: "",
      message: "",
      processing: false,
      registered: false,
    };
  },

  methods: {
    async register() {
      this.clearApiErrors();

      this.processing = true;

      try {
        await this.axios.post("/content-provider-requests", {
          name: this.name,
          last_name: this.lastname,
          email: this.email,
          message: this.message,
        });
      } catch (e) {
        this.handleApiErrors(e);

        this.processing = false;

        return;
      }

      this.registered = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.become-content-provider-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  background-image: url("~@/assets/img/become_content_provider_bg.png");
  background-size: auto 100%;
  background-position: right;
  background-repeat: no-repeat;

  @media (max-width: 991px) {
    box-shadow: inset 0 0 0 100vh rgba(255, 255, 255, 0.4);
  }
}

.become-content-provider-bg-mobile {
  background-image: url("~@/assets/img/become_content_provider_bg_mobile.png");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  height: 400px;
}

.newsletter-section-content {
  @media (max-width: 575px) {
    margin-top: -150px;
  }
}
</style>
